import { ProfileConstant, RoleConstant } from "./ProfileConstants";
import { ApiProfile, companyJobLink, ResumeBuilderAPIProfile, ResumeBuilderWebProfile, StudentDeepLinking } from "./profiles";

export const PortalUserRole = RoleConstant.ROLE_RECRUTER
export const LoginAutonavigateEnv = ProfileConstant.ProdRecruiterV1;

//resume builder api urls
const ResumeURL = ResumeBuilderAPIProfile.ProdV1
export const ResumeWebURL = ResumeBuilderWebProfile.ProdV1

//deeplink redirect
export const DynamicLink = StudentDeepLinking?.ProdV1
export const companyJobShareLink = companyJobLink?.ProdV1
const baseURL = ApiProfile.ProdApplicantV1

export const Url = {
  // --------- Login Urls ---------------------- 
  CountryCodes: baseURL + "countrycodes",
  SendOtp: baseURL + "sms/otp",
  UserLogin: baseURL + "login",
  mobileNoVerification: baseURL + 'public/user/countrycode/{countrycodeId}/username/{mobile_number}/iam/' + PortalUserRole,
  forgotPwd: baseURL + 'user/forgot-password',
  getuser: baseURL + "user",
  // --------- Login Urls  End---------------------- 

  // --------- Register Urls ---------------------- 
  registerUser: baseURL + 'user/sign-up',
  otpVerify: baseURL + 'sms/otp/varify',
  // --------- Register Urls End ---------------------- 

  // ---------------- Team Management Urls ------------------------- 
  managersTeam: baseURL + 'manager/team',
  hardDeleteUser: baseURL + "user/remove",
  managerUserAdd: baseURL + 'manager/user',
  deleteManagerUser: baseURL + 'manager/user/{userId}',
  getManagerUserById: baseURL + 'user/{userId}',
  updateManagerByUserId: baseURL + 'manager/user',
  // ---------------- Team Management Urls End------------------------- 

  // -------------------- Student Repository URL's -----------------------------
  getStudentList: baseURL + 'manager/team/student',
  deleteStudent: baseURL + "manager/user/{userId}",
  // -------------------- Student Repository URL's 

  getAllJobs: baseURL + 'job',
  getLatestAllJobs: baseURL + "job/latest",
  getAllDesignation: baseURL + "designation",
  getAllKeywords: baseURL + 'skill',
  GetExperienceList: baseURL + 'public/experience',
  GetJobType: baseURL + 'job/job-type',
  GetWorkMode: baseURL + 'job/work-mode',
  getAllLocation: baseURL + 'state',
  submitPrefernece: baseURL + 'user/preference',
  GetAllFilterdJob: baseURL + 'user/job/filter',
  getUserPrefernces: baseURL + 'user/preference',
  getJobDetailsByJobId: baseURL + 'job/{id}',
  GetAllSimilarJobs: baseURL + 'job/{jobId}/similar',
  GetJobsByCompanyUid: baseURL + 'company/{companyUid}/jobs',
  postApplyJob: baseURL + 'user/job',
  GetAllInterestedJob: baseURL + 'user/job/intrested',
  getJobDetailsByJobIDApplicant: baseURL + 'user/job/{jobID}',
  changePassword: baseURL + 'user/update-password',
  GetNotifications: baseURL + "notification",
  getNotificationCount: baseURL + "notification/count",
  DeleteRejectedApplications: baseURL + 'user/job/status/{userJobId}',
  GetJobsByRecruiterId: baseURL + 'job/user/{userId}',
  SendFeedBack: baseURL + 'feedback',
  GetPostApplicationStatus: baseURL + 'job/{jobId}/applicant',
  GetUserReports: baseURL + 'user/job/reports/{userId}',
  GetUserReportsByDate: baseURL + 'user/job/reports/{userId}/from/{fromDate}/to/{toDate}',
  getJobPostedByUser: baseURL + "job/user/{userId}",
  quikhireJobs : baseURL + "job/quik-hire",

  //admin and co-ordinater apis
  GetAppliedApplicantList: baseURL + "job/applicant",
  instituteAll: baseURL + 'institute/all',
  institute: baseURL + 'institute',
  sendReminderForAll: baseURL + "poster/reminder",
  uploadDoc: baseURL + 's3/public/institute-logo/upload', //post
  ChangeJobPostStatus: baseURL + "user/job",
  GetAppliedApplicantList: baseURL + "job/applicant",
  uploadXLStudent: baseURL + 'manager/upload/excel', //post
  downloadExcelDateWise : baseURL + 'manager/excel/{key}/from/{fromDate}/to/{toDate}',
  getStudentByMobileAndCountryCode: baseURL + 'user/campus/{mobile}/country/{countryCodeId}',
  downloadExcelOfRegisteredStudents: baseURL + 'manager/export/excel',
  downloadExcelOfSelectedJobReportCampus: baseURL + 'manager/excel/{key}/user/{userId}',
  downloadExcelOfSelectedJobReportQuikHire: baseURL + 'manager/quik-hire/excel/{key}/{mobile}/country/{country}',

  // --------------- insights Urls  ----------------------

  managerReport: baseURL + 'manager/weekly-team-report',
  allTotals: baseURL + 'manager/quick-totals',
  getReportByDates: baseURL + "manager/quick-totals/from/{fromDate}/to/{toDate}",
  downloadExcel: baseURL + 'manager/excel/',
  downloadExcelOfCampusOfferedStudents: baseURL + 'manager/excel/offeredStudents',
  downloadExcelOfQuikHireOfferedStudents: baseURL + 'manager/quik-hire/excel/selected',
  quikhireJobCount : baseURL + "manager/student/info",
  getAllCompanyNames: baseURL + "manager/company",
  getPlacementSummeryReport : baseURL + "user/manager/selected",
  getPlacementSummeryReportByDates : baseURL + "user/manager/selected/from/{fromDate}/to/{toDate}",

  //payment methods api urls
  getKeyAndPricing: baseURL + 'pricing', //GET api to get encode and decode key
  getOrderIdAndUpdatePaymentStatus: baseURL + 'payment/order', //POST for Generate OrderId and PUT for Order Status 
  paymentSuccessStatus: baseURL + 'manager/purchase-license', //POST
  validatePromoCode: baseURL + 'promo/{promocode}', //GET api to get promo code discount
  getAllTransactionHistory: baseURL + 'payment/history'
};

export const ResumeApiUrls = {
  GetResume: ResumeURL + 'user/resume/{mobile}/country/{countryCodeId}',
  GetResumeByUid: ResumeURL + 'user/resume/{userUid}',
}

