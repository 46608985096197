import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./JobDetails.css";
import jobLocationIcon from "../../assets/jobDetailsLocation.svg";
import calenderIcon from "../../assets/calenderIcon.svg";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import { getAPICall } from "../../APIMethods/APIMethods";
import { FaLink } from "react-icons/fa6";
import {
  companyJobShareLink,
  DynamicLink,
  Url,
} from "../../EnvoirnmentSetup/APIs";
import moment from "moment";
import { handleCopyDetails } from "../../constants/constantFunctions";
import { useSelectedCardContext } from "../../Context/Context";
import { LuClipboardEdit } from "react-icons/lu";

const JobDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const locationData = useLocation();
  const { IsLoading, setIsLoading, setWarning, setAlert ,
    jobModal,
    setJobCreateDetails,
    setJobModal,} =
    useSelectedCardContext();

  const [jobDetails, setJobDetails] = useState({});
  const [userID, setUserID] = useState(); 

  const handleBackToAllJobs = () => {
    navigate("/Main/latestJob");
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
    overlay: {
      zIndex: 3,
    },
  };

  useEffect(() => {
    GetJobDetails();
  }, [locationData?.state,jobModal]);

  const GetJobDetails = () => {
    setIsLoading(true);
    let jobId = locationData?.state?.jobId ?? params?.jobId;
    const user = JSON.parse(sessionStorage.getItem("user"));
    setUserID(user?.userId);
    getAPICall(Url.GetPostApplicationStatus.replace("{jobId}", jobId)).then(
      (res) => {
        setIsLoading(false);
        setJobDetails(res?.DATA);
      }
    );
  };
  const handleCopyCompanyJobShare = () => {
    const linkDetails =
      companyJobShareLink + locationData?.state?.encryptedJobId;
    handleCopyDetails(linkDetails);
    setAlert("Link Copied Successfully!");
  };
  const handleCopyStudentJobShare = () => {
    let jobIdData = jobDetails?.jobId;
    let message = `${DynamicLink}Main/JobDetails/${jobDetails?.jobId}`;
    handleCopyDetails(message);
    setAlert("Link Copied Successfully!");
    console.log(jobIdData);
    console.log(atob(jobIdData));
  };
  const handleEditJobDetails = () =>{
    let Data = {
      ...jobDetails,
      messageAuditId: 0,
      skill:
        jobDetails?.skill?.map((v) => ({
          ...v,
          value: v.skillId,
          label: v.name,
        })) ?? [],
      designation:
        jobDetails?.designation?.map((v) => ({
          ...v,
          value: v.designationId,
          label: v.designationName,
        })) ?? [],
      jobType: {
        ...jobDetails.jobType,
        value: jobDetails.jobType.jobTypeId,
        label: jobDetails.jobType?.jobType,
      },
      workMode: {
        ...jobDetails.workMode,
        value: jobDetails.workMode.workModeId,
        label: jobDetails.workMode?.workMode,
      },
      experience: {
        ...jobDetails.experience,
        value: jobDetails.experience.experienceId,
        label: jobDetails.experience?.experience,
      },
      state:
        jobDetails?.state?.map((v) => ({
          ...v,
          value: v.stateId,
          label: v.stateName,
        })) ?? [],
    }; 
    setJobCreateDetails(Data);
    setJobModal(true)
  }
 
  return (
    <div className="jobDetailsMainDiv">
      <div className="jobBreadCrumb">
        <ComponentAndScreenConstant.Breadcrumbs
          currentPage={["Job Details", "Jobs"]}
        />
      </div>
      <div className="jobDetailsAll">
        <div className="jobInfoDetails">
          <div className="jobInformation">
            <span className="jobTitleDetail">
              {jobDetails?.designation?.length > 0
                ? jobDetails?.designation[0]?.designationName
                : ""}
            </span>
            <span className="companyNameDetails">
              {jobDetails?.companyName}
            </span>
            <span className="salaryDetails">
              Salary: ₹{" "}
              {jobDetails?.isSalaryVisible && jobDetails?.salary
                ? jobDetails?.salary + " LPA"
                : "Not Disclosed"}
            </span>
            <div className="mainJobWorkDetails">
              <div className="jobWorkDetails">
                <span className="jobTypeDetails">
                  {jobDetails?.jobType?.jobType}
                </span>
                <span className="workTypeDetails">
                  {jobDetails?.workMode?.workMode}
                </span>
                <span className="expDetail">
                  {jobDetails?.experience?.experience}
                </span>
                <span className="positions">
                  Openings: {jobDetails?.noOfOpenings}
                </span>
              </div>
              <div className="jobActionButton">
                <button
                  type="button"
                  title="Share details to company"
                  onClick={() => {
                    handleCopyCompanyJobShare();
                  }}
                >
                  <FaLink className="ShareIcon" />
                  Company Share
                </button>
                <button
                  title="Share details to student"
                  type="button"
                  onClick={() => {
                    handleCopyStudentJobShare();
                  }}
                >
                  <FaLink className="ShareIcon" />
                  Student Share
                </button>
                <button onClick={()=>{handleEditJobDetails()}}><LuClipboardEdit /> Edit Job</button>
              </div>
            </div>
          </div>
          <div className="locationAndSimilar">
            {/* <button type='button' className='similarJob' onClick={() => { navigate('/Main/SimilarJobs', { state: { jobId: jobDetails?.jobId, headerHide: true, screen: locationData?.state?.screen, } }) }}>
                            <img src={similarJobIcon} alt="" /> <small>Similar Jobs</small>
                        </button> */}
            <span className="jobDetailLocation">
              <img src={jobLocationIcon} alt="" />{" "}
              <small
                title={jobDetails?.state
                  ?.map((state) => state.stateName)
                  .join(", ")}
              >
                {" "}
                {jobDetails?.state?.map((state) => state.stateName).join(", ")}
              </small>
            </span>
            {/* jobDetails?.state?.map(state => state.stateName).join(", ") */}
          </div>
        </div>
        <div className="activeTill">
          <span className="activeDate">
            Last Date to Apply : <img src={calenderIcon} alt="" />{" "}
            {jobDetails?.formattedActiveTillDate}
          </span>
          {/* <span className="uploadedDiv">
            {moment(jobDetails?.postedDateTime).fromNow()}
          </span> */}
        </div>
        <div className="jobDescription">
          <div className="descriptionContainer">
            <div className="keyWordInfo">
              <span className="keyWordsSelect">Keywords</span>
              <p>{jobDetails?.skill?.map((skill) => skill.name).join(", ")}</p>
            </div>
            <div className="jobDescriptionDetails">
              <span className="jobDescriptionDetailsHead">Job Description</span>
              <p>{jobDetails?.description}</p>
            </div>
          </div>
          <div className="countContainer">
            <button
              onClick={() => {
                jobDetails?.applicantCount?.intrested &&
                  navigate("/Main/appliedJobs", {
                    state: {
                      Flow: "Applied",
                      job: jobDetails,
                      jobId: jobDetails?.jobId,
                      headerHide: true,
                    },
                  });
              }}
              style={{
                cursor: jobDetails?.applicantCount?.intrested
                  ? "pointer"
                  : "not-allowed",
              }}
              title={
                jobDetails?.applicantCount?.intrested ? "Click here to see the applied candidates." : ""
              }
            >
              <div>
                {jobDetails?.applicantCount?.intrested
                  ? jobDetails?.applicantCount?.intrested < 10
                    ? "0" + jobDetails?.applicantCount?.intrested
                    : jobDetails?.applicantCount?.intrested
                  : "00"}
              </div>{" "}
              &nbsp; Applied
            </button>
            <button
              onClick={() => {
                jobDetails?.applicantCount?.viewd &&
                  navigate("/Main/viewedJobs", {
                    state: {
                      Flow: "Viewed",
                      job: jobDetails,
                      jobId: jobDetails?.jobId,
                      headerHide: true,
                    },
                  });
              }}
              title={
                jobDetails?.applicantCount?.viewd ? "Click here to see the viewed candidates." : ""
              }
              style={{
                cursor: jobDetails?.applicantCount?.viewd ? "pointer" : "not-allowed",
              }}
            >
              <div>
                {jobDetails?.applicantCount?.viewd
                  ? jobDetails?.applicantCount?.viewd < 10
                    ? "0" + jobDetails?.applicantCount?.viewd
                    : jobDetails?.applicantCount?.viewd
                  : "00"}
              </div>{" "}
              &nbsp; Viewed
            </button>
            <button
              onClick={() => {
                jobDetails?.applicantCount?.sendto &&
                  navigate("/Main/sentToJobs", {
                    state: {
                      Flow: "Notification Send",
                      job: jobDetails,
                      jobId: jobDetails?.jobId,
                      headerHide: true,
                    },
                  });
              }}
              title={
                jobDetails?.applicantCount?.sendto ? "Click here to see the notification sent candidates." : ""
              }
              style={{
                cursor: jobDetails?.applicantCount?.sendto
                  ? "pointer"
                  : "not-allowed",
              }}
            >
              <div>
                {jobDetails?.applicantCount?.sendto
                  ? jobDetails?.applicantCount?.sendto < 10
                    ? "0" + jobDetails?.applicantCount?.sendto
                    : jobDetails?.applicantCount?.sendto
                  : "00"}
              </div>{" "}
              &nbsp; Notification Send 
            </button>
            <button
              onClick={() => {
                jobDetails?.applicantCount?.interviewShortlisted &&
                  navigate("/Main/shortlistedList", {
                    state: {
                      Flow: "Shortlisted",
                      job: jobDetails,
                      jobId: jobDetails?.jobId,
                      headerHide: true,
                    },
                  });
              }}
              title={
                jobDetails?.applicantCount?.interviewShortlisted ? "Click here to see the shortlisted candidates." : ""
              }
              style={{
                cursor: jobDetails?.applicantCount?.interviewShortlisted
                  ? "pointer"
                  : "not-allowed",
              }}
            >
              <div>
                {jobDetails?.applicantCount?.interviewShortlisted
                  ? jobDetails?.applicantCount?.interviewShortlisted < 10
                    ? "0" + jobDetails?.applicantCount?.interviewShortlisted
                    : jobDetails?.applicantCount?.interviewShortlisted
                  : "00"}
              </div>{" "}
              &nbsp;
              <p>
                <h5 style={{ fontSize: "13px", fontWeight: "500" }}>
                  Shortlisted
                </h5>
              </p>
            </button>
            <button
              onClick={() => {
                jobDetails?.applicantCount?.interviewScheduled &&
                  navigate("/Main/interViewScheduled", {
                    state: {
                      Flow: "Interviewing",
                      job: jobDetails,
                      jobId: jobDetails?.jobId,
                      headerHide: true,
                    },
                  });
              }}
              title={
                jobDetails?.applicantCount?.interviewScheduled ? "Click here to see the interview scheduled candidates." : ""
              }
              style={{
                cursor: jobDetails?.applicantCount?.interviewScheduled
                  ? "pointer"
                  : "not-allowed",
              }}
            >
              <div>
                {jobDetails?.applicantCount?.interviewScheduled
                  ? jobDetails?.applicantCount?.interviewScheduled < 10
                    ? "0" + jobDetails?.applicantCount?.interviewScheduled
                    : jobDetails?.applicantCount?.interviewScheduled
                  : "00"}
              </div>{" "}
              &nbsp; Interviewing
            </button>
            <button
              onClick={() => {
                jobDetails?.applicantCount?.selected &&
                  navigate("/Main/hiredCandidates", {
                    state: {
                      Flow: "Hired",
                      job: jobDetails,
                      jobId: jobDetails?.jobId,
                      headerHide: true,
                    },
                  });
              }}
              style={{
                cursor: jobDetails?.applicantCount?.selected
                  ? "pointer"
                  : "not-allowed",
              }}
              title={
                jobDetails?.applicantCount?.selected ? "Click here to see the hired candidates." : ""
              }
            >
              <div>
                {jobDetails?.applicantCount?.selected
                  ? jobDetails?.applicantCount?.selected < 10
                    ? "0" + jobDetails?.applicantCount?.selected
                    : jobDetails?.applicantCount?.selected
                  : "00"}
              </div>{" "}
              &nbsp; Hired
            </button>
            <button
              onClick={() => {
                jobDetails?.applicantCount?.closed &&
                  navigate("/Main/declinedCandidates", {
                    state: {
                      Flow: "Declined",
                      job: jobDetails,
                      jobId: jobDetails?.jobId,
                      headerHide: true,
                    },
                  });
              }}
              style={{
                cursor: jobDetails?.applicantCount?.closed
                  ? "pointer"
                  : "not-allowed",
              }}
              title={
                jobDetails?.applicantCount?.closed ? "Click here to see the rejected candidates." : ""
              }
            >
              <div>
                {jobDetails?.applicantCount?.closed
                  ? jobDetails?.applicantCount?.closed < 10
                    ? "0" + jobDetails?.applicantCount?.closed
                    : jobDetails?.applicantCount?.closed
                  : "00"}
              </div>{" "}
              &nbsp; Declined
            </button>
          </div>
        </div>
      </div>

      <div className="backbtnparent">
        <div className="backbtnwrapper">
          <button className="backbtn" onClick={handleBackToAllJobs}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
